<template>
  <!-- begin::Offers tabs -->
  <div v-if="isAuthenticated">
    <div class="manager-title">
      <inline-svg
        class="manager-title-icon"
        src="/media/icons/ofertas-primary.svg"
      />
      <div>
        {{ $t("MENU.OFFERS") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div v-if="!currentUserCompanyIsPremium">
      <OffersStandardIndex />
    </div>

    <div v-else>
      <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
        <v-tabs-slider color="#61bb0c"></v-tabs-slider>

        <v-tab grow v-for="t in TabList" :key="t.Position" :href="'#' + t.Name">
          {{ t.Title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items class="mx-0" :value="ActiveTab">
        <v-tab-item v-for="t in TabList" :key="t.Position" :value="t.Name">
          <div v-if="t.Name === 'standard'">
            <OffersStandardIndex />
          </div>

          <div v-if="t.Name === 'special'">
            <OffersSpecialIndex />
          </div>

          <div v-if="t.Name === 'tender'">
            <OffersTenderIndex />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
  <!-- end::Offers tabs -->
</template>

<script>
import { mapGetters } from "vuex";
import OffersSpecialIndex from "@/view/pages/manager/shipments/offer/special/Index.vue";
import OffersStandardIndex from "@/view/pages/manager/shipments/offer/standard/Index.vue";
import OffersTenderIndex from "@/view/pages/manager/shipments/offer/tender/Index.vue";

export default {
  name: "OffersTabs",
  components: {
    OffersSpecialIndex,
    OffersStandardIndex,
    OffersTenderIndex
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUserCompanyIsPremium"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    }
  },
  data() {
    return {
      TabList: [
        {
          Position: 0,
          Name: "standard",
          Title: this.$i18n.t("OFFERS.STANDARD.TITLE_INDEX")
        },
        {
          Position: 1,
          Name: "special",
          Title: this.$i18n.t("OFFERS.SPECIAL.TITLE_INDEX")
        },
        {
          Position: 2,
          Name: "tender",
          Title: this.$i18n.t("OFFERS.TENDER.TITLE_INDEX")
        }
      ]
    };
  }
};
</script>
