import ApiService from "@/core/services/api/v2/api.service";

const OFFER_STATUS_LIST_KEY = "ShipmentOfferStatusList";
const TRACKING_STATUS_LIST_KEY = "ShipmentTrackingStatusList";
const ALL_STATUS_LIST_KEY = "ShipmentAllStatusList";

const ShipmentStatusService = {
  listAtOffer() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(OFFER_STATUS_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.get("shipment/offerStatus")
          .then(response => {
            let newList = response.data.response.ShipmentStatuses;

            window.sessionStorage.setItem(
              OFFER_STATUS_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  listAtTracking() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(TRACKING_STATUS_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.get("shipment/trackingStatus")
          .then(response => {
            let newList = response.data.response.ShipmentStatuses;

            window.sessionStorage.setItem(
              TRACKING_STATUS_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  },

  listAll() {
    return new Promise(resolve => {
      // This list is widely used and changes rarely, so cache its values
      let cachedList = window.sessionStorage.getItem(ALL_STATUS_LIST_KEY);
      if (cachedList) {
        resolve(JSON.parse(cachedList));
      } else {
        ApiService.get("shipment/allStatus")
          .then(response => {
            let newList = response.data.response.ShipmentStatuses;

            window.sessionStorage.setItem(
              ALL_STATUS_LIST_KEY,
              JSON.stringify(newList)
            );

            resolve(newList);
          })
          .catch(() => {
            resolve([]);
          });
      }
    });
  }
};

export default ShipmentStatusService;
