<template>
  <!--begin::OffersTender index-->
  <div class="gray-box">
    <div
      class="white-box shadow-box p-12"
      title="Pendiente de definir"
      style="height: 50vh"
    >
      TBD...
    </div>
  </div>
  <!--end::OffersTender index-->
</template>

<script>
export default {
  name: "OffersTenderIndex"
};
</script>
