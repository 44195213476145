<template>
  <!--begin::OffersStandard index-->
  <div class="gray-box">
    <!--begin::Row Filters-->
    <div class="white-box mb-9">
      <div class="row pt-6 align-items-top">
        <div class="col-md-6 d-flex d-row align-items-top">
          <h5 class="mr-3 mt-3 font-weight-bolder" style="white-space: pre">
            {{ $t("OFFERS.COMMON.SEARCH") }}
          </h5>
          <v-text-field
            class="form-control search-text"
            v-model.lazy="SearchText"
            :placeholder="$t('GENERIC.SEARCH') + '...'"
            @keyup="onSearchTextKeyup"
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/search-alt-1-primary.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="col-md-3 pt-3">
          <span
            class="action-icon"
            :class="[ShowFilters ? 'bg-primary' : '']"
            @click="() => (ShowFilters = !ShowFilters)"
          >
            <inline-svg
              v-if="ShowFilters"
              src="/media/icons/filter-alt-1.svg"
            />
            <inline-svg v-else src="/media/icons/filter-alt-1-primary.svg" />
          </span>
        </div>

        <div class="col-md-3 text-right p-0">
          <button
            class="btn button-filter btn-pill btn-tertiary"
            @click="downloadExcel"
            :disabled="DownloadingExcel"
          >
            <inline-svg src="/media/icons/download-alt-2.svg" class="mr-3" />
            {{ $t("OFFERS.COMMON.EXCEL_TITLE_1") }}
          </button>
        </div>
      </div>

      <div v-if="ShowFilters" class="row mt-3 py-6 box-filters">
        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.ORIGIN") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByOriginProvinceID"
            @change="searchOffers(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.DESTINATION") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByDestinationProvinceID"
            @change="searchOffers(false)"
          >
            <option
              v-for="p in ProvinceOptions"
              :key="p.ProvinceID"
              :value="p.ProvinceID"
              class="text-primary"
            >
              {{ p.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4">
          <label>
            {{ $t("OFFERS.COMMON.STATUS") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByShipmentStatusID"
            @change="searchOffers(false)"
          >
            <option
              v-for="s in StatusOptions"
              :key="s.ShipmentStatusID"
              :value="s.ShipmentStatusID"
              class="text-primary"
            >
              {{ s.Name }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 pt-3">
          <label>
            {{ $t("OFFERS.COMMON.PATH") }}
          </label>
          <select
            class="form-control select-down"
            v-model="FilterByLegCount"
            @change="searchOffers(false)"
          >
            <option value="0" class="text-primary">
              &nbsp;
            </option>
            <option value="1" class="text-primary">
              {{ $t("OFFERS.COMMON.JUST_THERE") }}
            </option>
            <option value="2" class="text-primary">
              {{ $t("OFFERS.COMMON.THERE_AND_BACK") }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 pt-3">
          <label>
            <span v-if="currentUserRoleID === rolesList.ID_TRANSPORTER_ADMIN">
              {{ $t("OFFERS.COMMON.SENDER") }}
            </span>
            <span v-else>
              {{ $t("OFFERS.COMMON.TRANSPORTER") }}
            </span>
          </label>
          <v-text-field
            class="form-control"
            v-model="FilterByCompanyName"
            @keyup="onSearchTextKeyup"
          />
        </div>

        <div class="col-lg-4 pt-10 pl-9">
          <button
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="cleanFilters"
          >
            <inline-svg src="/media/icons/cross-primary.svg" />
            {{ $t("GENERIC.CLEAN_FILTERS") }}
          </button>
        </div>
      </div>
    </div>
    <!--end::Row Filters-->

    <!--begin::Row Searching-->
    <div v-if="ItemsSearching" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.SEARCHING") }}...
    </div>
    <!--end::Row Searching-->

    <!--begin::Table Results-->
    <v-data-table
      id="OffersTable"
      v-if="ItemsList.length > 0"
      class="white-table shadow-box"
      :headers="TableHeaders"
      :items="ItemsList"
      :items-per-page="ItemsPerPage"
      :options.sync="options"
      :server-items-length="ItemsTotal"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      hide-default-footer
    >
      <template v-slot:header.OtherCompany="{ header }">
        <span v-if="currentUserRoleID === rolesList.ID_TRANSPORTER_ADMIN">
          {{ $t("OFFERS.COMMON.SENDER") }}
        </span>
        <span v-else>
          {{ $t("OFFERS.COMMON.TRANSPORTER") }}
        </span>
      </template>

      <template v-slot:item="{ item }">
        <tr
          :class="
            item.ShipmentStatusID > 6 && item.ShipmentStatusID != 14
              ? 'bg-primary-o-30'
              : ''
          "
        >
          <td class="pt-3">
            <span
              class="action-icon"
              :class="[item.AlertCountOther > 0 ? 'border-danger' : '']"
              @click="
                $router.push(
                  '/manager/offers/standard/edit/' +
                    item.ShipmentID +
                    '?ActiveTab=review'
                )
              "
            >
              <inline-svg
                :src="
                  '/media/icons/edit-alt-2-' +
                    (item.AlertCountOther > 0 ? 'danger' : 'primary') +
                    '.svg'
                "
              />
            </span>

            <span
              class="action-icon ml-3"
              :class="[item.AlertCountChat > 0 ? 'border-danger' : '']"
              @click="
                $router.push(
                  '/manager/offers/standard/edit/' +
                    item.ShipmentID +
                    '?ActiveTab=messages'
                )
              "
            >
              <inline-svg
                :src="
                  '/media/icons/chat-' +
                    (item.AlertCountChat > 0 ? 'danger' : 'primary') +
                    '.svg'
                "
              />
            </span>
          </td>

          <td>
            <span v-if="item.AlertCountChat + item.AlertCountOther === 0">
              {{ item.ShipmentID }}
            </span>
            <v-badge v-else left inline color="bg-danger text-white">
              <template v-slot:badge>
                {{ item.AlertCountChat + item.AlertCountOther }}
              </template>
              <span class="ml-1">{{ item.ShipmentID }}</span>
            </v-badge>
          </td>

          <td class="pt-4">
            <span
              class="white-box border-2 p-3 font-size-sm text-uppercase"
              :class="formatStatusClass(item.ShipmentStatusID)"
              style="white-space: nowrap"
            >
              {{ formatStatusName(item.ShipmentStatusID) }}
            </span>
          </td>

          <td>
            {{ formatDate(item.CreatedAt) }}
          </td>

          <td>
            <p
              v-if="item.Back.Vehicles.length > 0"
              class="font-weight-bolder m-0"
            >
              {{ $t("OFFERS.COMMON.THERE").toUpperCase() }}:
            </p>
            <p
              v-for="(v, index) in item.There.Vehicles"
              :key="'V_' + item.ShipmentID + '_There_' + index"
              class="m-0"
            >
              <strong style="white-space: nowrap;">
                {{
                  v.Brand +
                    ", " +
                    v.Model +
                    ", " +
                    v.Version +
                    ", " +
                    v.Year +
                    ". " +
                    v.VehicleTypeName +
                    ", " +
                    v.VehicleStatusName +
                    "."
                }}
              </strong>
              <br />
              {{ v.Plate }}
            </p>
            <p
              v-if="item.Back.Vehicles.length > 0"
              class="font-weight-bolder m-0"
            >
              {{ $t("OFFERS.COMMON.BACK").toUpperCase() }}:
            </p>
            <p
              v-for="(v, index) in item.Back.Vehicles"
              :key="'V_' + item.ShipmentID + '_Back_' + index"
              class="m-0"
            >
              <strong style="white-space: nowrap;">
                {{
                  v.Brand +
                    ", " +
                    v.Model +
                    ", " +
                    v.Version +
                    ", " +
                    v.Year +
                    ". " +
                    v.VehicleTypeName +
                    ", " +
                    v.VehicleStatusName +
                    "."
                }}
              </strong>
              <br />
              {{ v.Plate }}
            </p>
          </td>

          <td>
            <strong style="white-space: nowrap;">
              {{ item.OriginAddress.TradeName }}
            </strong>
            <br />
            {{ item.OriginAddress.Street }}
            <br />
            {{
              item.OriginAddress.PostalCode + " - " + item.OriginAddress.City
            }}
          </td>

          <td>
            <strong style="white-space: nowrap;">
              {{ item.DestinationAddress.TradeName }}
            </strong>
            <br />
            {{ item.DestinationAddress.Street }}
            <br />
            {{
              item.DestinationAddress.PostalCode +
                " - " +
                item.DestinationAddress.City
            }}
          </td>

          <td>
            <span v-if="item.Back.Vehicles.length > 0">
              {{ $t("OFFERS.COMMON.THERE_AND_BACK") }}
            </span>
            <span v-else>
              {{ $t("OFFERS.COMMON.JUST_THERE") }}
            </span>
          </td>

          <td>
            {{
              item.TransporterName.length > 0
                ? item.TransporterName
                : item.SenderName
            }}
          </td>

          <td>
            <strong style="white-space: nowrap;">
              {{
                item.Price.toFixed(2).replace(".", ",") + " " + item.Currency
              }}
            </strong>
            <br />
            {{ $t("FARE_TYPE_NAMES_BY_ID." + item.FareTypeID) }}
          </td>

          <td>
            <span
              v-if="item.Back.Vehicles.length > 0"
              class="font-weight-bolder"
            >
              {{ $t("OFFERS.COMMON.THERE").toUpperCase() }}:
              <br />
            </span>
            {{ formatOriginDate("There", item.There.OriginDateDesired) }}
            <span
              v-if="item.Back.Vehicles.length > 0"
              class="font-weight-bolder"
            >
              {{ $t("OFFERS.COMMON.BACK").toUpperCase() }}:
            </span>
            <span v-if="item.Back.Vehicles.length > 0">
              {{ formatOriginDate("Back", item.Back.OriginDateDesired) }}
            </span>
          </td>

          <td>
            <span
              v-if="item.Back.Vehicles.length > 0"
              class="font-weight-bolder"
            >
              {{ $t("OFFERS.COMMON.THERE").toUpperCase() }}:
              <br />
            </span>
            {{
              formatDestinationDate(
                item.There.DestinationDateDesiredFrom,
                item.There.DestinationDateDesiredTo
              )
            }}
            <br />
            {{
              item.There.DestinationDateDesiredTimeFrom +
                " - " +
                item.There.DestinationDateDesiredTimeTo
            }}
            <span
              v-if="item.Back.Vehicles.length > 0"
              class="font-weight-bolder"
            >
              {{ $t("OFFERS.COMMON.BACK").toUpperCase() }}:
            </span>
            <span v-if="item.Back.Vehicles.length > 0">
              {{
                formatDestinationDate(
                  item.Back.DestinationDateDesiredFrom,
                  item.Back.DestinationDateDesiredTo
                )
              }}
              <br />
              {{
                item.Back.DestinationDateDesiredTimeFrom +
                  " - " +
                  item.Back.DestinationDateDesiredTimeTo
              }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <!--end::Table Results-->

    <!--begin::Row Pagination-->
    <div v-if="!ItemsSearching && ItemsList.length > 0" class="p-0 row">
      <div
        class="p-4 d-flex justify-content-between align-items-center flex-wrap w-100"
      >
        <div>
          {{
            $t("GENERIC.PAGINATION_SHOW", {
              from: ItemsFrom,
              to: ItemsTo,
              total: ItemsTotal
            })
          }}
        </div>

        <v-pagination
          v-model="ItemsCurrentPage"
          :length="ItemsPages"
          @input="searchOffers(true)"
          :previous-aria-label="$t('GENERIC.PREVIOUS')"
          :next-aria-label="$t('GENERIC.NEXT')"
        />

        <div class="d-flex align-items-baseline py-3">
          <v-select
            class="form-control form-control-sm mr-0 border-0 bg-light"
            style="max-width: 5em;"
            v-model="ItemsPerPage"
            single-line
            :items="ItemsPerPageOptions"
            @change="updateItemsPerPage"
          />
          <span>{{ $t("GENERIC.PAGINATION_MENU") }}</span>
        </div>
      </div>
    </div>
    <!--end::Row Pagination-->

    <!--begin::Row Zero-->
    <div v-if="!ItemsSearching && ItemsList.length < 1" class="search-info">
      <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>
    <!--end::Row Zero-->
  </div>
  <!--end::OffersStandard index-->
</template>

<style scoped>
#OffersTable thead th {
  white-space: nowrap !important;
}
#OffersTable tbody td {
  vertical-align: top !important;
  padding-top: 6px;
  padding-bottom: 6px;
}
.afterOffer {
  background-color: #f3f4f6 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import ProvinceService from "@/core/services/api/v2/province.service";
import ShipmentService from "@/core/services/api/v2/shipment.service";
import ShipmentStatusService from "@/core/services/api/v2/shipmentstatus.service";

export default {
  name: "OffersStandardIndex",
  data() {
    return {
      TableHeaders: [
        { text: this.$t("GENERIC.ACTIONS"), width: 120, sortable: false },
        { text: this.$t("OFFERS.COMMON.ID"), value: "ShipmentID", width: 80 },
        {
          text: this.$t("OFFERS.COMMON.STATUS"),
          value: "ShipmentStatusID",
          width: 120
        },
        {
          text: this.$t("OFFERS.COMMON.CREATION_DATE"),
          value: "CreatedAt",
          width: 130
        },
        { text: this.$t("OFFERS.COMMON.VEHICLES"), value: "Vehicles" },
        {
          text: this.$t("OFFERS.COMMON.ORIGIN"),
          value: "OriginAddress",
          width: 180
        },
        {
          text: this.$t("OFFERS.COMMON.DESTINATION"),
          value: "DestinationAddress",
          width: 180
        },
        { text: this.$t("OFFERS.COMMON.PATH"), value: "LegCount", width: 130 },
        { text: ".", value: "OtherCompany", width: 150 },
        { text: this.$t("OFFERS.COMMON.FARE"), value: "Price" },
        {
          text: this.$t("OFFERS.COMMON.ORIGIN_DATE_DESIRED"),
          value: "OriginDateDesired",
          width: 120
        },
        {
          text: this.$t("OFFERS.COMMON.DESTINATION_DATE_DESIRED"),
          value: "OriginDateDesiredFrom",
          width: 120
        }
      ],
      options: {},
      ProvinceOptions: [],
      StatusOptions: [],
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      SortBy: "ShipmentID",
      SortDesc: true,
      ItemsList: [],
      ItemsSearching: true,
      DebounceSearchText: null,
      SearchText: "",
      ShowFilters: false,
      FilterByOriginProvinceID: 0,
      FilterByDestinationProvinceID: 0,
      FilterByShipmentStatusID: 0,
      FilterByCompanyName: "",
      FilterByLegCount: 0,
      ItemsFrom: 0,
      ItemsTo: 0,
      ItemsTotal: 0,
      ItemsPerPage: 5,
      ItemsPages: 1,
      ItemsCurrentPage: 1,
      ItemsPerPageOptions: [
        { text: 5, value: 5 },
        { text: 10, value: 10 },
        { text: 25, value: 25 },
        { text: 50, value: 50 }
      ],
      DownloadingExcel: false
    };
  },

  computed: {
    ...mapGetters(["currentUserRoleID", "rolesList"])
  },

  created() {
    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFormatLang = this.DateFormatES;
    }
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadStatusOptions();
    this.loadProvinceOptions();
  },

  methods: {
    loadStatusOptions() {
      this.StatusOptions = [];
      this.StatusOptions.push({ ShipmentStatusID: 0, Name: " " });

      ShipmentStatusService.listAtOffer().then(response => {
        response.forEach(s => {
          this.StatusOptions.push(s);
        });
      });
    },

    loadProvinceOptions() {
      this.ProvinceOptions = [];
      this.ProvinceOptions.push({ ProvinceID: 0, Name: " " });

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceOptions.push(p);
        });

        this.loadCachedFilters();

        // Remove page loader
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

        // Do first search
        this.searchOffers(false);
      });
    },

    loadCachedFilters() {
      let anyFilterNotEmpty = false;

      Object.entries(ShipmentService.getCachedFiltersAtOffer()).forEach(f => {
        this["FilterBy" + f[0]] = f[1];

        if (!anyFilterNotEmpty) {
          anyFilterNotEmpty = parseInt(f[1]) > 0;
        }
      });

      this.ShowFilters = anyFilterNotEmpty;
    },

    searchOffers(keepCurrentPage) {
      this.ItemsSearching = true;
      this.ItemsList = [];

      // Compute pagination params
      if (!keepCurrentPage) {
        this.ItemsCurrentPage = 1;
      }

      const limit = this.ItemsPerPage;
      const offset =
        this.ItemsCurrentPage === 0 ? 0 : limit * (this.ItemsCurrentPage - 1);

      ShipmentService.searchAtOffer(
        this.SearchText,
        this.FilterByOriginProvinceID,
        this.FilterByDestinationProvinceID,
        this.FilterByShipmentStatusID,
        this.FilterByCompanyName,
        this.FilterByLegCount,
        this.SortBy,
        this.SortDesc,
        limit,
        offset
      ).then(rawResponse => {
        if (rawResponse === 404) {
          this.ItemsList = [];
          this.ItemsPages = 1;
        } else {
          this.ItemsList = rawResponse.response.Shipments;

          this.ItemsTotal = rawResponse.total;
          this.ItemsPages = Math.ceil(this.ItemsTotal / this.ItemsPerPage);
          this.ItemsFrom = (this.ItemsCurrentPage - 1) * this.ItemsPerPage + 1;
          this.ItemsTo = this.ItemsFrom + this.ItemsList.length - 1;
        }

        this.ItemsSearching = false;
      });
    },

    updateSortBy(event) {
      if (event.length > 0) {
        this.SortBy = event[0];
        this.SortDesc = false;
        this.ItemsCurrentPage = 1;
        this.searchOffers(false);
      }
    },
    updateSortDesc(event) {
      this.SortDesc = event.length > 0 ? event[0] : this.SortDesc;
      this.ItemsCurrentPage = 1;
      this.searchOffers(false);
    },
    updateItemsPerPage(newValue) {
      this.ItemsPerPage = newValue;
      this.ItemsCurrentPage = 1;
      this.searchOffers(false);
    },

    changeItemsCurrentPage() {
      this.searchOffers(true);
    },

    cleanFilters() {
      this.FilterByOriginProvinceID = 0;
      this.FilterByDestinationProvinceID = 0;
      this.FilterByShipmentStatusID = 0;
      this.FilterByCompanyName = "";
      this.FilterByLegCount = 0;
      this.ShowFilters = false;
      this.searchOffers(false);
    },

    onSearchTextKeyup() {
      clearTimeout(this.DebounceSearchText);
      this.DebounceSearchText = setTimeout(() => {
        this.searchOffers(false);
      }, 600);
    },

    formatDate(date) {
      if (date && date.length > 0) {
        return moment(date).format(this.DateFormatLang);
      } else {
        return "-";
      }
    },

    formatOriginDate(whichLeg, date) {
      if (whichLeg === "Back" && date === "0001-01-01") {
        return this.$t("OFFERS.COMMON.ORIGIN_DATE_DESIRED_BACK_THERE");
      } else {
        return this.formatDate(date);
      }
    },

    formatDestinationDate(dateFrom, dateTo) {
      if (dateFrom === "" && dateTo === "") {
        return this.$t("OFFERS.COMMON.FLEXIBLE");
      } else if (dateFrom === dateTo) {
        return this.formatDate(dateFrom);
      } else {
        return this.formatDate(dateFrom) + " - " + this.formatDate(dateTo);
      }
    },

    formatStatusName(statusID) {
      if (statusID <= 9 || statusID == 14) {
        // It's really at offer phase
        return this.$t("SHIPMENT_STATUS_NAMES_BY_ID." + statusID);
      } else {
        // It's after offer phase
        return this.$t("OFFERS.COMMON.STATUS_AFTER_OFFER");
      }
    },

    formatStatusClass(statusID) {
      if (statusID <= 5) {
        // It's negotiating
        return "";
      } else if (statusID == 6 || statusID == 7 || statusID == 14) {
        // It's cancelled or rejected
        return "text-danger border-danger";
      } else {
        // It's accepted, after offer phase
        return "text-success border-success";
      }
    },

    downloadExcel() {
      // Disable download button, because this may take a while
      this.DownloadingExcel = true;

      ShipmentService.downloadExcelAtOffer(
        this.SearchText,
        this.FilterByOriginProvinceID,
        this.FilterByDestinationProvinceID,
        this.FilterByShipmentStatusID,
        this.FilterByCompanyName,
        this.FilterByLegCount,
        this.SortBy,
        this.SortDesc
      ).then(rawResponse => {
        if (rawResponse === 400) {
          this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
            title: this.$i18n.t("GENERIC.SAVE_ERROR"),
            variant: "danger",
            solid: true
          });
        } else {
          const tempBlob = new Blob([rawResponse], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const tempLink = document.createElement("a");
          tempLink.href = URL.createObjectURL(tempBlob);
          tempLink.download = this.$t("OFFERS.STANDARD.DOWNLOAD_NAME");
          tempLink.click();
          URL.revokeObjectURL(tempLink.href);
        }

        // Re-enable button
        this.DownloadingExcel = false;
      });
    }
  }
};
</script>
